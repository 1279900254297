<template>
  <div class="panel login-panel">
    <div class="panel-head">{{title}}</div>
    <div class="panel-body">
      <el-form ref="loginForm" :rules="rules" :model="model" method="post" action="'/auth/login" id="loginForm"
               onsubmit="return false" label-width="80px">
        <el-form-item prop="loginAccount" label="登录账号">
          <el-input v-model="model.loginAccount" placeholder="登录账号"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="loginPassword">
          <el-input v-model="model.loginPassword" type="password" placeholder="登录密码"></el-input>
        </el-form-item>


        <el-form-item label="验证码" prop="_captcha">
          <el-input :maxlength="4" placeholder="验证码" v-model="model._captcha" style="width: 100px"></el-input>
          <captcha-image ref="captcha-image"/>
        </el-form-item>


        <el-form-item>
          <el-button type="primary" :loading="locked" @click="submitForm('loginForm')">立即登录</el-button>
        </el-form-item>
      </el-form>

      <div>
        <router-link :to="{name:'authRegister'}" class="pull-left">免费注册</router-link>
        <router-link :to="{name:'authForget'}" class="pull-right">忘记密码</router-link>
      </div>
      <div class="clear">
      </div>
    </div>
  </div>
</template>
<script>
  import CaptchaImage from '../common/CaptchaImage'

  export default {
    name: 'AuthLogin',
    components: {CaptchaImage},
    data: function () {
      return {
        title: '账号登录',
        model: {
          loginAccount: '',
          loginPassword: '',
          _captcha: ''
        },
        locked: false,
        rules: {
          loginAccount: [
            {required: true, message: '请输入登录账号', 'trigger': 'blur'},
            {max: 36, message: '登录账号长度超过限制', 'trigger': 'blur'}
          ],
          loginPassword: [
            {required: true, message: '请输入登录密码', 'trigger': 'blur'},
            {min: 6, max: 16, message: '密码长度范围为6-16个字符', 'trigger': 'blur'}
          ],
          _captcha: [
            {required: true, message: '请输入验证码', 'trigger': 'blur'}
          ]
        }
      }
    },
    methods: {
      submitForm (formName) {
        const self = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            self.locked = true
            self.$http.post({
              url: '/auth/login.action',
              data: self.model,
              success () {
                self.$router.push({name: 'memberIndex'})
              },
              fail (response) {
                self.$refs['captcha-image'].changeImg()
                self.$http.errorHandle(response)
              },
              always () {
                self.locked = false
              }
            })
          } else {
            return false;
          }
        })
      }
    }
  }
</script>
<style scoped>
  .login-panel {
    height: 350px;
    margin-top: -175px;
  }


</style>
